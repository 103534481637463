import styled from "styled-components";

export const StyledWearable = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;
export const WearableImage = styled.div`
  width: 220px;
  height: 220px;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid #aeb1b8;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
