import { configureStore } from "@reduxjs/toolkit";

import deviceReducer from "./features/devices/deviceSlice";
import entityReducer from "./features/entities/entitySlice";
import authReducer from "./features/auth/authSlice";

export const store = configureStore({
  reducer: {
    devices: deviceReducer,
    entities: entityReducer,
    auth: authReducer
  }
});
