import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActivePage } from "../../../features/auth/authSlice";
import { AccountOptions, Option, Divider } from "../styled/Header";

const Header = () => {
  const dispatch = useDispatch();
  const { activePage } = useSelector((state) => state.auth);

  return (
    <AccountOptions>
      <Option
        active={activePage === "register"}
        onClick={() => dispatch(setActivePage("register"))}
      >
        Register
      </Option>
      <Divider />
      <Option
        active={activePage === "signin"}
        onClick={() => dispatch(setActivePage("signin"))}
      >
        Sign In
      </Option>
    </AccountOptions>
  );
};

export default Header;
