import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import Spinner from "../../../components/Spinner";
import Wearable from "./Wearable";
import { MainContainer, ProductsContainer } from "./styled/Products";

import { getDevices } from "../../../features/devices/deviceSlice";

const ProductsTable = () => {
  const dispatch = useDispatch();
  const { devices, filteredDevices, isLoading } = useSelector(
    (state) => state.devices
  );

  const [availableDevices, setAvailableDevices] = useState([]);
  const { entities } = useSelector((state) => state.entities);
  const [page, setPage] = useState(1);

  // TODO hacking time, the above is matching entity id with brand... some of them don't match (if you add extra entites and they don't match extend this)

  useEffect(() => {
    const entitiesIdsFilter = entities.map((ent) =>
      ent.id === "rosan_diamond"
        ? "rosan diamond"
        : ent.id === "stiss"
        ? "stiss augmented watches"
        : ent.id
    );
    if (entities.length > 0) {
      const devicesFiltereByEntities = filteredDevices.filter((e) =>
        entitiesIdsFilter.includes(e.brand)
      );
      setAvailableDevices(devicesFiltereByEntities);
    } else {
      setAvailableDevices(filteredDevices);
    }
  }, [entities, filteredDevices]);

  useEffect(() => {
    if (devices.length === 0) {
      dispatch(getDevices());
    }
  }, [dispatch, devices]);

  function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  if (isLoading) return <Spinner data-testid="products-table-spinner" />;

  return (
    <MainContainer data-testid="products-table">
      <ProductsContainer>
        <React.Suspense fallback={<Spinner />}>
          {availableDevices?.length > 0 &&
            paginate(availableDevices, 12, page).map((device, i) => (
              <Wearable device={device} key={i} />
            ))}
        </React.Suspense>
      </ProductsContainer>

      {availableDevices.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          noPages={Math.round(availableDevices.length / 12) + 1}
        />
      )}
    </MainContainer>
  );
};

export default ProductsTable;
