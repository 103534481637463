import styled from "styled-components";

export const ProfileContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 0.5px solid #aeb1b8;
  border-radius: 12px;
  width: 260px;
  margin-right: 40px;
`;
export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 25px;
  color: #797b80;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #aeb1b8;
`;

export const ProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;

  img: {
    max-width: 100%;
    max-height: 100%;
  }
`;
export const LinksContainer = styled.div``;
export const LinkButton = styled.div`
  color: ${({ active }) => (active ? "#2A0EE4" : "inherit")};
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: #2a0ee4;
  }
`;
