import React, { useState } from "react";
import { useSelector } from "react-redux";
import Heading from "../../components/Heading";

import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";
import Header from "./components/Header";
import Register from "./components/Register";
import Signin from "./components/Signin";
import { StyledContainer } from "./styled/Container";

const Account = () => {
  const [type, setType] = useState("register");
  const { activePage } = useSelector((state) => state.auth);

  return (
    <StyledMain grayBg>
      <MainContainer>
        <StyledContainer>
          <Header type={type} onChangeType={(newType) => setType(newType)} />
          <h2>
            {activePage === "register"
              ? "Register with your email"
              : "Welcome back"}
          </h2>
          {activePage === "register" ? <Register /> : <Signin />}
        </StyledContainer>
      </MainContainer>
    </StyledMain>
  );
};

export default Account;
