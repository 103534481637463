import styled from "styled-components";

export const Card = styled.div`
  min-width: ${(props) => (props.large ? "742px" : "358px")};
  background-color: white;
  border: 0.5px solid #aeb1b8;
  border-radius: 12px;
  padding: 2.5rem;
  margin-bottom: 1.25rem;

  h2 {
    margin: 0 0 20px;
    text-align: left;
  }
`;
export const ListElement = styled.div`
  width: 100%;
  border-top: 0.5px solid #aeb1b8;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  p {
    width: 80%;
  }
`;
