import companyLogo from "./images/logo.svg";

const people = {
  colin_tanner: {
    name: "Colin",
    surname: "Tanner",
    title: "CTO",
    photo: require("./images/Colin-Tanner.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "colin.tanner@digiseq.co.uk",
    phone: "+44(0)7790 337778",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  alex_komarov: {
    name: "Alex",
    surname: "Komarov",
    title: "Head of Products",
    photo: require("./images/Alex-Komarov.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "alex.komarov@digiseq.co.uk",
    phone: "+44(0)7856 438198",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  andy_ramsden: {
    name: "Andy",
    surname: "Ramsden",
    title: "Business Development Consultant",
    photo: require("./images/Andy-Ramsden.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "andy.ramsden@digiseq.co.uk",
    phone: "44(0)7525 687168",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  nigel_palmer: {
    name: "Nigel",
    surname: "Palmer",
    title: "Sales Executive",
    photo: require("./images/Nigel-Palmer.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "nigel.palmer@digiseq.co.uk",
    phone: "+44(0)7969 026928",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  iain_case: {
    name: "Iain",
    surname: "Case",
    title: "Solution Integration Manager",
    photo: require("./images/Iain-Case.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "iain.case@digiseq.co.uk",
    phone: "+44(0)7790 337778",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  melanie_koestler: {
    name: "Melanie",
    surname: "Koestler",
    title: "Head of Innovation & Growth",
    photo: require("./images/Melanie-Koestler.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "melanie.koestler@digiseq.co.uk",
    phone: "+49(0)1768 1944230",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  lenny_davies: {
    name: "Lenny",
    surname: "Davies",
    title: "Product Marketing Manager",
    photo: require("./images/Lenny-Davies.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "lenny.davies@digiseq.co.uk",
    phone: "+44(0)7437 014210",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  ido_kariti: {
    name: "Ido",
    surname: "Kariti",
    title: "Director",
    photo: require("./images/ido-kariti.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "ido.kariti@digiseq.co.uk",
    phone: "+972 5424 760350",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  terrie_smith: {
    name: "Terrie",
    surname: "Smith",
    title: "Ambassador",
    photo: require("./images/Terrie-Smith.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "terrie.smith@digiseq.co.uk",
    phone: "+44(0)7850 05683",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  }
};

export default people;
