import React from "react";

import {
  StyledTopSection,
  Banner,
  Details,
  Title,
  AppStoreImgs,
  ButtonsContainer
} from "./styled/TopSection";

import bannerImg from "./images/homepage-banner.png";
import appStoreImg from "./images/app-store.svg";
import googlePlayImg from "./images/google-play.svg";
import { GoButton } from "../../brands/components/styled/FeaturedBrand";
import { Link } from "react-router-dom";

const TopSection = () => {
  return (
    <StyledTopSection>
      <Banner>
        <img src={bannerImg} alt="slide" />
        <Details>
          <Title>Where Fashion Meets Payment Technology</Title>
          <ButtonsContainer>
            <Link to="/products">
              <GoButton>Shop wearables</GoButton>
            </Link>

            <AppStoreImgs>
              <img
                src={appStoreImg}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/gb/app/manage-mii/id1436896422",
                    "_blank"
                  )
                }
                alt="app store"
              />
              <img
                src={googlePlayImg}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.managemii",
                    "_blank"
                  )
                }
                alt="google play"
              />
            </AppStoreImgs>
          </ButtonsContainer>
        </Details>
      </Banner>
    </StyledTopSection>
  );
};

export default TopSection;
