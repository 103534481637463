import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/Input";
import Spinner from "../../../components/Spinner";
import { PrimaryButton } from "../../../components/styled/PrimaryButton";
import { FormContainer } from "../styled/FormContainer";

import { register, reset } from "../../../features/auth/authSlice";

const Register = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: ""
  });

  const { email, password, confirmPassword } = formData;

  console.log({ formData });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      console.log({ message });
    }

    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
      confirmPassword
    };

    dispatch(register(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <FormContainer onSubmit={onSubmit}>
      <Input
        id="email"
        name="email"
        type="email"
        label="Email"
        value={email}
        required
        onChange={onChange}
      />
      <Input
        type="password"
        id="password"
        name="password"
        label="Password"
        value={password}
        onChange={onChange}
        required
      />
      <Input
        type="password"
        id="confirmPassword"
        name="password"
        label="Re-enter password"
        value={password}
        onChange={onChange}
        required
      />
      <PrimaryButton>Register</PrimaryButton>
    </FormContainer>
  );
};

export default Register;
