import { Link } from "react-router-dom";
import styled from "styled-components";

export const WearablesContainer = styled.div`
  background-color: white;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 50px;
  border: 0.5px solid #aeb1b8;
  border-radius: 12px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #aeb1b8;
  margin-bottom: 30px;

  h2 {
    margin: 0;
  }
`;
export const HeaderLink = styled(Link)`
  color: #2a0ee4;
  cursor: pointer;
`;
export const Wearables = styled.div`
  display: flex;
`;
