import React, { useState, useEffect } from "react";
import VCard from "vcard-creator";
import {
  Container,
  Nav,
  Header,
  NameSection,
  Photo,
  DetailsContainer,
  Item,
  ItemValue,
  QR,
  SocialIcons,
  Divider
} from "./styled/BusinessCard";

import downloadIcon from "./img/download.svg";
import instagramIcon from "./img/instagram.svg";
import twitterIcon from "./img/twitter.svg";
import linkedinIcon from "./img/linkedin.svg";
import whatsappIcon from "./img/whatsapp.svg";
import { useNavigate, useParams } from "react-router-dom";

import mockedData from "./mocked-data/people";

const BusinessCard = () => {
  const [qrData, setQrData] = useState("");
  const [downloadData, setDownloadData] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  function download(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/x-vcard;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  useEffect(() => {
    if (mockedData.hasOwnProperty(params.id)) {
      const { name, surname, title, company, email, phone, address } =
        mockedData[params.id];

      const [street, worktown, workpostcode, country] = address.split(";");
      const vCard = new VCard();
      vCard
        .addName(surname, name, "", "", "")
        .addCompany(company)
        .addJobtitle(title)
        .addEmail(email)
        .addPhoneNumber(phone, "WORK")
        .addAddress("", "", street, worktown, "", workpostcode, country);

      setQrData(vCard.toString().replace(/\n/g, "%0A"));
      setDownloadData(vCard.buildVCard());
    } else navigate("/");
  }, [navigate, params.id]);

  if (mockedData.hasOwnProperty(params.id)) {
    return (
      <Container>
        <Nav>
          <img src={mockedData[params.id].companyLogo} alt="logo" />
          <img
            style={{ cursor: "pointer" }}
            src={downloadIcon}
            alt="download"
            onClick={() =>
              download(
                `${mockedData[params.id].name}-${
                  mockedData[params.id].surname
                }`,
                downloadData
              )
            }
          />
        </Nav>
        <Divider />
        <Header>
          <NameSection>
            <h2>
              {mockedData[params.id].name} {mockedData[params.id].surname}
            </h2>
            <p>
              {mockedData[params.id].title} • {mockedData[params.id].company}
            </p>
          </NameSection>
          <Photo>
            <img src={mockedData[params.id].photo} alt="user" />
          </Photo>
        </Header>
        <Divider />
        <DetailsContainer>
          <Item>
            <p>Email</p>
            <ItemValue>{mockedData[params.id].email}</ItemValue>
          </Item>
          <Divider />
          <Item>
            <p>Phone number</p>
            <ItemValue>{mockedData[params.id].phone}</ItemValue>
          </Item>
          <Divider />
          <Item>
            <p>Address</p>

            <DetailsContainer>
              {mockedData[params.id].address.split(";").map((e, i) => (
                <ItemValue key={i}>{e}</ItemValue>
              ))}
            </DetailsContainer>
          </Item>
          <Divider />
          <Item>
            <p>Social</p>
            <SocialIcons>
              <a
                href={mockedData[params.id].social.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagramIcon} alt="instagram" />
              </a>
              <a
                href={mockedData[params.id].social.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedinIcon} alt="linkedin" />
              </a>
              <a
                href={mockedData[params.id].social.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterIcon} alt="twitter" />
              </a>
              <a
                href={
                  mockedData[params.id].social.whatsapp +
                  mockedData[params.id].phone
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={whatsappIcon} alt="whatsapp" />
              </a>
            </SocialIcons>
          </Item>
          <Divider />
          {qrData && (
            <QR
              alt="qr"
              src={`https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${qrData}`}
            />
          )}
        </DetailsContainer>
      </Container>
    );
  }
  return null;
};

export default BusinessCard;
