import styled from "styled-components";

export const Container = styled.section`
  margin: 100px auto;
  width: 100%;
  max-width: 358px;
  padding: 17px 20px;
  border-radius: 14px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.32);

  h2,
  p {
    font-family: "Arimo", sans-serif;
    font-style: normal;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #8d8e99;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e1e1e5;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
  img {
    width: 40px;
    height: 40px;
    &:last-of-type {
      cursor: pointer;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

export const NameSection = styled.div`
  max-width: 80%;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1d1d1f;
  }
`;

export const Photo = styled.div`
  width: 56px;
  height: 56px;
  resize: none;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DetailsContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
`;

export const Item = styled.li`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
`;
export const ItemValue = styled.p`
  &&& {
    color: #1d1d1f;
    text-align: right;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
`;

export const QR = styled.img`
  height: 184px;
  width: 184px;
  margin: auto;
`;
