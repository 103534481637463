import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import deviceService from "./deviceService";

// TODO this is mapping filters to our types, we need to add more types in the system so each filter will have its own type. as now some filters share same type (e.g charms, rings they are all JEWELRY)
const filters = {
  charms: "JEWELRY",
  jewellery: "JEWELRY",
  fobs: "TAG",
  rings: "JEWELRY",
  nails: "TAG",
  watches: "WATCH_OR_WRISTBAND"
};

const initialState = {
  device: null,
  devices: [],
  filteredDevices: [],
  userDevices: [],
  devicesByBrand: [],
  filter: ["charms", "jewellery", "fobs", "rings", "nails", "watches"],
  sort: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ""
};

// get public devices
export const getDevices = createAsyncThunk(
  "devices/getAll",
  async (_, thunkAPI) => {
    try {
      return await deviceService.getDevices();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get user devices
export const getUserDevices = createAsyncThunk(
  "devices/getUserDevicesAll",
  async (_, thunkAPI) => {
    try {
      return await deviceService.getUserDevices();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDevicesByBrand = createAsyncThunk(
  "devices/getAllByBrand",
  async (brand, thunkAPI) => {
    try {
      return await deviceService.getDevicesByBrand(brand);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDeviceByModel = createAsyncThunk(
  "devices/getDeviceByModel",
  async (deviceModel, thunkAPI) => {
    const { brand, model, version } = deviceModel;
    try {
      return await deviceService.getDeviceByModel(brand, model, version);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetDevice: (state) => {
      state.device = null;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;

      const filterCriteria = action.payload.map((e) => filters[e]);

      state.filteredDevices = state.devices.filter((e) =>
        filterCriteria.some((x) => x === e.type)
      );
    },
    setSort: (state, action) => {
      state.sort = action.payload;

      switch (state.sort) {
        case "featured":
          state.filteredDevices = state.devices.filter(
            (e) => e.featuredOnWebsite
          );
          break;

        case "new to old":
          state.filteredDevices = state.filteredDevices.sort(
            (a, b) => a.createdDate - b.createdDate
          ); // TODO test this if works!
          break;
        case "old to new":
          state.filteredDevices = state.filteredDevices.sort(
            (a, b) => b.createdDate - a.createdDate
          ); // TODO test this if works!
          break;

        default:
          state.filteredDevices = state.devices;

          // TODO this is reseting and filters (to improve sort and keep filters we need an extra copy as unsorted that can be restored here - nice to have feature)
          break;
      }
    },

    setDevice: (state, action) => {
      state.device = action.payload;
    },
    resetDevicesByBrand: (state) => {
      state.devicesByBrand = [];
    },
    resetUserDevices: (state) => {
      state.userDevices = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.devices = action.payload
          .filter((e) => !e.excludedFromWebsite)
          .filter((e) => e.status !== "DEPRECATED")
          .filter((e) => e.status !== "PROTOTYPE")
          .filter(
            (v, i, a) =>
              a.findIndex(
                (v2) => v2.modelDisplayName === v.modelDisplayName
              ) === i
          );

        state.filteredDevices = state.devices;
      })
      .addCase(getDevices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserDevices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userDevices = action.payload;
      })
      .addCase(getUserDevices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDevicesByBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDevicesByBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.devicesByBrand = action.payload
          .filter((e) => !e.excludedFromWebsite)
          .filter((e) => e.status !== "DEPRECATED")
          .filter((e) => e.status !== "PROTOTYPE")
          .filter(
            (v, i, a) =>
              a.findIndex(
                (v2) => v2.modelDisplayName === v.modelDisplayName
              ) === i
          );
      })
      .addCase(getDevicesByBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDeviceByModel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeviceByModel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.device = action.payload;
      })
      .addCase(getDeviceByModel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const {
  reset,
  resetDevice,
  resetDevicesByBrand,
  resetUserDevices,
  resetFilter,
  setFilter,
  setSort,
  setDevice
} = deviceSlice.actions;
export default deviceSlice.reducer;
