import styled from "styled-components";

export const AccountOptions = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #aeb1b8;
  margin-bottom: 10px;
`;

export const Option = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  /* padding-bottom: 20px; */
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ active }) => (active ? "-3px" : 0)};
  padding-bottom: ${({ active }) => (active ? "22px" : "20px")};
  border-bottom: ${({ active }) => (active ? "2px solid #1d1d1f" : "")};
  cursor: pointer;
`;

export const Divider = styled.div`
  border: 0.5px solid #aeb1b8;
  height: 60%;
  margin-bottom: 16px;
`;
