import styled from "styled-components";

export const StyledSearchBar = styled.div`
  display: flex;
  padding: 5px 15px;
  border: 0.5px solid #aeb1b8;
  border-radius: 10px;

  img {
    margin-right: 4px;
  }
  input {
    border: none;
    height: 30px;
    width: 100%;
    font-size: 1.063rem;
    color: #797b80;
  }

  input:focus {
    outline: none;
  }
`;
