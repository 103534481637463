import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Homepage from "./pages/homepage/Homepage";
import Account from "./pages/account/Account";
import Products from "./pages/products/Products";
import Product from "./pages/product/Product";
import HelpCenter from "./pages/help-center/HelpCenter";
import Footer from "./components/Footer";
import Contact from "./pages/contact/Contact";
import Policies from "./pages/policies/Policies";
import Guide from "./pages/guide/Guide";
// import NotFound from "./pages/not-found/NotFound";
import { StyledApp } from "./styled/StyledApp";
import ScrollToTop from "./hooks/ScrollToTop";
import Brand from "./pages/brand/Brand";
import Brands from "./pages/brands/Brands";
import Configuration from "./Configuration";
import MyWearables from "./pages/dashboard/wearables/MyWearables";
import ManOfTheMatch from "./pages/man-of-the-match/ManOfTheMatch";
import ManOfTheMatchSubmitted from "./pages/man-of-the-match-submitted/ManOfTheMatchSubmitted";
import BusinessCard from "./pages/business-card/BusinessCard";

function App() {
  return (
    <>
      <Router>
        <Configuration />
        <ScrollToTop />
        <StyledApp>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/products" element={<Products />} />
            <Route
              path="/products/:brand/:model/:version"
              element={<Product />}
            />
            <Route path="/brands" element={<Brands />} />
            <Route path="/brands/:brand" element={<Brand />} />
            {/* <Route path="/help" element={<HelpCenter />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/account" element={<Account />} />
            {/* <Route path="/policies" element={<Policies />} />
            <Route path="/guide" element={<Guide />} /> */}
            {/* <Route path="/wearables" element={<MyWearables />} /> */}
            <Route path="/business-card/:id" element={<BusinessCard />} />

            <Route
              path="/real-betis-vs-celta-vigo-mom"
              element={<ManOfTheMatch />}
            />
            <Route
              path="/nomination-submitted"
              element={<ManOfTheMatchSubmitted />}
            />
            <Route path="*" element={<Homepage />} />
          </Routes>
        </StyledApp>
      </Router>
    </>
  );
}

export default App;
